import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { grey } from "@mui/material/colors";
import { Typography } from "@mui/material";
import * as gtag from "components/GTagManager";

export default function Footer() {
  const theme = useTheme();

  const menuLinkContentObj = {
    Loans: [
      { name: "Get loan quote", route: "/loans" },
      { name: "DSCR loan", route: "/dscr-loan" },
      {
        name: "Our loan terms",
        route: "/capital",
      },
    ],
    Calculators: [
      { name: "DSCR calculator", route: "/blog/dscr-calculator" },
      {
        name: "Construction loan calculator",
        route: "/blog/construction-loan-calculator",
      },
      {
        name: "Cash Offer calculator",
        route: "/blog/construction-loan-calculator",
      },
      { name: "Escrow calculator", route: "/blog/escrow-calculator" },
    ],
    Sell: [{ name: "Sell property", route: "/sell" }],
    Listings: [
      { name: "Map", route: "/listings" },
      { name: "Directory", route: "/listings-directory" },
    ],
    Wholesalers: [
      { name: "Wholesalers", route: "/wholesalers" },
      { name: "List a wholesale deal", route: "/sell" },
    ],
    Resources: [
      { name: "Blog", route: "/blog/all" },
      { name: "Guides", route: "/blog/guides" },
      { name: "Tools", route: "/blog/tools" },
      { name: "Glossary", route: "/blog/glossary" },
      { name: "Markets", route: "/blog/markets" },
      { name: "Referrals", route: "/referral-program" },
      { name: "Insurance", route: "/insurance" },
    ],
    Company: [
      { name: "About", route: "/about" },
      { name: "Contact", route: "/contact" },
    ],
  };
  return (
    <footer
      key={"footer"}
      style={{
        margin: "0",
        fontSize: theme.spacing(1.75),
        backgroundColor: theme.palette.primary.dark,
        color: "white",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        padding: `${theme.spacing(5)} 0`,
      }}
    >
      <Box
        sx={{
          maxWidth: theme.spacing(150),
          width: "100%",
          margin: "0 auto",
          padding: `0 ${theme.spacing(2.5)}`,
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: "15%" },
            mb: { xs: 4, md: 0 },
          }}
        >
          <Typography
            variant="h5"
            component="div"
            sx={{ fontWeight: "bold", mb: 2 }}
          >
            OfferMarket
          </Typography>

          <Box sx={{ mb: 3 }}>
            <Box
              sx={{
                margin: theme.spacing(1, "auto", 0, "auto"),
                display: "flex",
                flexDirection: "column",

                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              }}
            >
              <Button
                color="success"
                sx={{
                  textDecoration: "none !important",
                  color: "white !important",
                  margin: theme.spacing(1.5, 0, 0, 0),
                  backgroundColor: `${theme.palette.success.main} !important`,
                  "&:hover": {
                    color: `${theme.palette.primary.contrastColor} !important`,
                    backgroundColor: `${theme.palette.success.dark} !important`,
                  },
                  [theme.breakpoints.down("md")]: {
                    width: "auto",
                  },
                }}
                href={"/loans?ref=footer-get-quote-btn"}
                onClick={() => {
                  gtag.event({
                    action: "footer-get-quote-btn",
                  });
                }}
              >
                <Box
                  sx={{
                    margin: theme.spacing(0, 1),
                    fontSize: theme.spacing(2),
                    padding: theme.spacing(0.5),
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  ⚡
                </Box>
                Get instant loan quote{" "}
              </Button>
              <Typography
                component="h2"
                sx={{
                  display: "flex",
                  fontSize: theme.spacing(1.5),
                  margin: theme.spacing(0, 0, 0, "auto"),
                  [theme.breakpoints.down("md")]: {
                    display: "block",
                  },
                }}
              >
                *takes 2 minutes, no credit pull
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flex: 1,
            gap: { xs: 1, md: 3 },
            margin: `0 0 0 ${theme.spacing(10)}`,
          }}
        >
          {Object.keys(menuLinkContentObj).map((item, i) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "45%", sm: "30%", md: "auto" },
                flex: { md: "1 1 0" },
                mb: 3,
              }}
              key={`${i}_footerNavSection_${item}`}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 600,
                  textAlign: "left",
                  textTransform: "uppercase",
                  mb: 2,
                  color: "white",
                }}
              >
                {item}
              </Typography>

              {menuLinkContentObj[item].map((childLink, index) => (
                <Box
                  key={`${i}_${index}_footer_links`}
                  sx={{ mb: 1, textAlign: "left" }}
                >
                  <Link
                    href={childLink.route}
                    sx={{
                      color: "white !important",
                      textDecoration: "none",
                      fontSize: theme.spacing(1.5),
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {childLink.name}
                  </Link>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          maxWidth: theme.spacing(150),
          width: "100%",
          margin: `${theme.spacing(2.5)} auto 0`,
          padding: `0 ${theme.spacing(2.5)}`,
          borderTop: "1px solid rgba(255,255,255,0.1)",
          pt: 3,
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Typography
            variant="body2"
            sx={{
              opacity: 0.8,
              fontSize: theme.spacing(1.25),
              lineHeight: 1.3,
            }}
          >
            <Box
              sx={{
                textAlign: "left",
                marginBottom: theme.spacing(1),
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                  padding: `0 ${theme.spacing(5)}%`,
                },
              }}
            >
              OfferMarket is a private lender and marketplace for assignment of
              contract and for sale by owner listings. By accessing and
              utilizing our services, you understand, acknowledge and agree that
              neither OfferMarket nor any of its representatives makes any
              representation or warranty, express or implied, as to the accuracy
              or completeness of any listings.​ You are expected to conduct your
              own due diligence.
            </Box>
            <Box
              sx={{
                textAlign: "left",
                marginBottom: theme.spacing(1),
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                  padding: `0 ${theme.spacing(5)}%`,
                },
                "& a": {
                  color: "rgba(255, 255, 255, 0.8)",
                  textDecoration: "underline",
                },
              }}
            >
              If you are having trouble reading this website or having screen
              reader issues, please call OfferMarket at call{" "}
              <Link
                sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                rel="noopener"
                target="_blank"
                href="tel:+14434929941"
              >
                1-443-492-9941
              </Link>{" "}
              or email{" "}
              <Link
                sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                rel="noopener"
                target="_blank"
                href="mailto:hello@offermarket.us"
              >
                hello@offermarket.us
              </Link>
              .{" "}
              <Link
                sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                rel="noopener"
                target="_blank"
                href="/accessibility-statement"
              >
                Accessibility statement
              </Link>
              .
            </Box>
            <Box
              sx={{
                textAlign: "left",
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                  padding: `0 ${theme.spacing(5)}%`,
                },
                "& a": {
                  color: "rgba(255, 255, 255, 0.8)",
                  textDecoration: "underline",
                },
              }}
            >
              By using our site, you agree to our{" "}
              <Link
                sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                rel="noopener"
                target="_blank"
                href="/terms-of-service"
              >
                Terms
              </Link>{" "}
              and{" "}
              <Link
                sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                rel="noopener"
                target="_blank"
                href="/privacy-policy"
              >
                Privacy Policy
              </Link>
              .
            </Box>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "flex-start", md: "center" },
          }}
        >
          <Box sx={{ mb: { xs: 2, md: 0 } }}>
            <Link
              href="https://www.facebook.com/OfferMarketUS"
              target="_blank"
              sx={{ mr: 1, color: "white !important" }}
            >
              <FacebookIcon
                sx={{ fontSize: theme.spacing(3.5), color: "white" }}
              />
            </Link>
            <Link
              href="https://www.instagram.com/offermarketus"
              target="_blank"
              sx={{ mr: 1, color: "white !important" }}
            >
              <InstagramIcon
                sx={{ fontSize: theme.spacing(3.5), color: "white" }}
              />
            </Link>
            <Link
              href="https://twitter.com/OfferMarketUS"
              target="_blank"
              sx={{ mr: 1, color: "white !important" }}
            >
              <TwitterIcon
                sx={{ fontSize: theme.spacing(3.5), color: "white" }}
              />
            </Link>
            <Link
              href="https://www.youtube.com/channel/UCRoNHXFVgI36SV0QQpyf9fw"
              target="_blank"
              sx={{ color: "white !important" }}
            >
              <YouTubeIcon
                sx={{ fontSize: theme.spacing(3.5), color: "white" }}
              />
            </Link>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <Link
              href="/privacy-policy"
              sx={{ color: "white !important", fontSize: theme.spacing(1.25) }}
            >
              Privacy Policy
            </Link>
            <Link
              href="/terms-of-service"
              sx={{ color: "white !important", fontSize: theme.spacing(1.25) }}
            >
              Terms of Service
            </Link>
            <Link
              href="/accessibility-statement"
              sx={{ color: "white !important", fontSize: theme.spacing(1.25) }}
            >
              Accessibility
            </Link>
            <Typography
              variant="body2"
              sx={{ color: "white", fontSize: theme.spacing(1.25) }}
            >
              Copyright OfferMarket 2025
            </Typography>
          </Box>
        </Box>
      </Box>
    </footer>
  );
}
